import React , { useEffect, useState } from "react";
import "./App.css";

function App({ settings }) {
  const [data, setData] = useState([]);
  const { field = "" } = settings;
  const url = `${process.env.REACT_APP_API_URL}?api_key=${process.env.REACT_APP_API_KEY}`;

  function httpGetAsync(url, callback) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
        callback(xmlHttp.responseText ? JSON.parse(xmlHttp.responseText) : {});
    };
    xmlHttp.open("GET", url, true); // true for asynchronous
    xmlHttp.send(null);
  }

  useEffect(() => {
    httpGetAsync(url, (response) => {
      if (response && Object.keys(response).length > 0) {
        const ip = response.ip_address || "";
        const region = response.region || "";
        const city = response.city || "";
        const country = response.country || "";
        const postal_code = response.postal_code || "";
        const connection_type = response.connection?.connection_type || "";
        const isp_name = response.connection?.isp_name || "";
        const organization_name = response.connection?.organization_name || "";
        const arrData = [];
        if (ip) {
          arrData.push({ label: "IP", value: ip });
        }
        if (region) {
          arrData.push({ label: "Region", value: region });
        }
        if (city) {
          arrData.push({ label: "City", value: city });
        }
        if (country) {
          arrData.push({ label: "Country", value: country });
        }
        if (postal_code) {
          arrData.push({ label: "Postal Code", value: postal_code });
        }
        if (connection_type) {
          arrData.push({ label: "Connection Type", value: connection_type });
        }
        if (isp_name) {
          arrData.push({ label: "ISP", value: isp_name });
        }
        if (organization_name) {
          arrData.push({ label: "Organization", value: organization_name });
        }
        setData(arrData);
      }
    });
  }, [url]);

  if (data.length > 0) {
    if (!field) {
      return (
        <ul className="geo-locations">
          {data.map(({ label, value }) => (
            <li key={label}>
              <div className="label"><strong>{label}:</strong></div>
              <div>{value}</div>
            </li>
          ))}
        </ul>
      );
    }
    const res = data.find(
      (x) => x?.label?.toLowerCase() === field?.toLowerCase()
    );
    return <>{res?.value || ""}</>;
  }
  return <></>;
}

export default App;
